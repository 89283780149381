import './styles/title.css';

export const Title = () => {
  return (
    // Title
    <div>
      <h1 className="title_name">
        Alessandro Branco
      </h1>
      <h2 className = "title_description">
        Data Analyst
      </h2>
      <p className="title_paragraph">
        Transforming Data into Actionable Insights
      </p>
    </div>
  )
}
