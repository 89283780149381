const experienceData = [
    {
      id: 1,
      date: "2021 — Current",
      position: "Sales Manager",
      company: "Dania 18 California",
      description: "As Sales Manager at Dania18 California LLC, I increased online revenue by 35% through Power BI and Salesforce enhancements, and boosted team productivity by 20% with a new CRM system. I created accurate reports using advanced Excel formulas and developed dashboards that improved data-driven decisions by 25%, effectively presenting sales data for informed decision-making."
    },
    {
      id: 2,
      date: "2013 — 2019",
      position: "Mathematics Teacher",
      company: "MOPI",
      description: "As a Mathematics Teacher, I increased advanced math class enrollment by 20% in three months through innovative teaching methods. I enhanced student engagement and participation by 20% with an engaging curriculum, creating a dynamic learning environment. Additionally, I mentored struggling students, significantly improving their performance."
    }
];

export default experienceData;